@media (max-width: 992px) {
    .carousel-caption {
        justify-content: center;
    }

    .caption-right {
        justify-content: center !important;
    }

    .carousel,.carousel-inner {
        min-height: 25vh;
            .carousel-item {
                min-height: 25vh;
                overflow: hidden !important;
                img{
                    flex-shrink: 0;
                    min-height: 25vh;
                    object-fit: cover;
                }
            }

    }
}

.carousel {
    .carousel-inner {
        .carousel-caption {
            position: absolute;
            top: 0;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            height: 100%;
        }

        .caption-right {
            justify-content: right;
        }
    }
}