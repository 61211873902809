.container-row {
    padding: 10px;
    display: flex !important;
    text-align: center !important;
}

.form-col {
    display: flex;
    padding: 10px;
    justify-content: center;
}

.legend-row {
    justify-content: center;
    text-align: center;
}

.input-fields {
    align-content: center;
}

.input {
    width: 100%;
}

.container-form {
    width: 100%;
}

.form-row {
    justify-content: center;
}

.input-row {
    justify-content: center;
}

.legend-col {
    padding: 50px;
}

.legends {
    min-width: 200px;
}

.submit-button {
    margin: 0px 0px 20px 0px !important;
}

.confirmation-col {
    text-align: center;
}

.confirmation-text {
    color: green;
    font-size: smaller;
}