@import './Colors.scss';

.navbar-box {
    display: flex;
}

.toggler{
    border-style: none !important;
}

.navbar-styles{ 
    justify-content: center !important;   
    Nav{
        align-items: center !important;
    }
}

.navbar-logo{
    z-index: 3;
    font-size: 1.45rem;
    margin-left: 13px;

    @media (max-width: 900px) {
        display: none !important;
    }
}

.navbar-logo-img{
    margin-right: 10px;
    align-self: center;
}

.navbar-logo-text{
    padding-top: 3px;
}

.navbar-logo-text:hover {
    color: #000 !important;
}

.navbar-logo-img-mobile {
    margin-right: 10px;
    display: none !important;
    @media (max-width: 900px) {
        display: block !important;
    }
}

.navbar-logo-text-mobile {
    display: none !important;
    @media (max-width: 900px) {
        display: block !important;
    }
}

@media (min-width: 992px){
    .navbar-styles{
        margin-left: -166px;
        z-index: 0;
    }
    .navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
} 

.navbar-link{
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: 2rem;
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
}

.dropdown-toggle{
    padding-left: 8.8px !important;
}

.navbar-link {
    font-size: 1rem;
    width: 100%;
}

.navbar-link:hover{
    color: $completeit-blue !important;
}

.active{
    color:$completeit-blue !important;
}

.navbar-logo-link {
    color: black !important;
    display: flex;
}

.navbar-toolbar {
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-divider {
    border-right-width: 1px !important;
    height: 20px !important;
    align-self: center !important;
    background-color: black;
    opacity: 0.4 !important;
}

.navbar-divider-mobile {
    align-self: center !important;
    background-color: black;
    opacity: 0.4 !important;
}

.navbar-spacer {
    flex-grow: 1;
    @media (max-width: 900px) {
        display: none !important;
    }
}

.nav-button {
    color: #000 !important;
    height: 45px;
}

.visited {
    color: #009fe3 !important;
}

.navbar-menu-button-mobile {
    margin: 0px 10px 0px 10px !important;
    padding: 0px !important;
}