.banner {
    width: 90%;
    aspect-ratio: 1/1.35;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
}

.banner-row {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
}

@media (min-width: 992px) {
    .banner-row {
        flex-direction: row;
    }
}

.banner-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
}

.service-banner {
    background-image: url(../media/service.webp);
    background-position-x: -140px;
}

.configuration-banner {
    background-image: url(../media/configuration.webp);
}

.maintenance-banner {
    background-image: url(../media/maintenance.webp);
    background-position-x: -250px;
}

.banner-header {
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}