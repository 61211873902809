@import "../styles/Colors.scss";
@import "../index.css";

.profile-row-top {
    align-items: center;
    justify-content: center;
}

.container-row{
    text-align: center !important;
}

.profile-row-bottom {
    align-items: center;
    justify-content: center;
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.company-text {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.text-row {
    margin: 0;
    position: absolute;
    top: 50%;
}

.text {
    margin: 0;
}

.profile-image {
    width: 100%;
}

.titel {
    padding: 10px;
    display: flex !important;
    text-align: center !important;
}

.heading {
    color: $completeit-darkblue;
    font-family: 'HeeboBold';
}

h4 {
    color: $completeit-darkblue;
    font-family: 'Heebo';
}