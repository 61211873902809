@import './Colors.scss';

.header-image {
    width: 100%;
}

.header {
    position: relative;
    text-align: center;
    color: white;
}

.header-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
    .infra-container {
        margin-top: -100px;
    }
}

.network-graphic {
    width: 75% !important;
}

.highlight {
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
}

.highlight-col {
    padding: 10px !important;
    margin: 20px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    div {
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .header-text{
        top: 50% !important;
    }
    .infra-container {
        margin-top: 15px;
    }
}

.infra-img-row {
    position: relative;
    text-align: center;
}

.infra-img {
    display: flex;
}

.infra-img-col {
    width: 50%;

    img {
        width: 100%;
        margin: 0px;
    }
}

.overlay-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay-logo {
    width: 20% !important;
    margin: 0px;
    background-color: $completeit-background;
    border-radius: 50%;
}