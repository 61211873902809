@import './Colors.scss';

* {
    font-size: 1.1rem;
    font-family: "Heebo";
}

.page-container {
    min-height: 70vh; //only for dev
    background-color: $completeit-background;
    margin-top: -5px;
}

a:hover{
    color: $completeit-blue !important;
}

.error{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    .error-banner{
        height: 30vh;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
}