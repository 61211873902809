.website-container {
    margin: 0 !important;
    max-width: none !important;
}

.container-row {
    justify-content: center;
}

.outside-paper-row {
    margin: 0;
}

.paper-row {
    justify-content: center;
    text-align: left;
}

.paper-col {
    padding: 20px;
}

.mockup-col{
    text-align: center;
    .mockup {
        width: 100%;
        max-width: 45vw;
        min-width: 300px;
    }
}


.paper-text-row {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-button {
    color: #1976d2 !important;
}

.card-col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
}

.info-text {
    margin: 0px;
    font-size: smaller;
}