@import './Colors.scss';

@media (max-width: 992px) {
    .footer-row{
        flex-direction: column;
        text-align: center;
    }
}

.footer{
    background-image: $gradiant-default;
    color: white;
    bottom: 0;
    width: 100%;
}

.footer-row{
    display: flex;
    justify-content: center;
    padding: 5px;
}

.footer-column{
    margin: 0px 30px 10px 30px;
}

.column-heading{
    font-size: 1.2rem;
    font-weight: bold;
}


.footer-links{
    display: flex;
    flex-direction: column;
}

.icon{
    margin: 5px;
    height: 30px;
}

a {
    text-decoration: none !important;
    color: inherit !important;

    :hover {
        cursor: pointer;
        color: $completeit-blue;
    }
}