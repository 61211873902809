@import './Colors.scss';

.container-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 5px !important;
}

.container-row {
    margin: 5px 0px 5px 0px !important;
    padding: 10px 0px 10px 0px !important;
}

.package-card{
    min-width: 290px;
    height: 400px;
}

.top-background-div {
    background-image: $gradiant-default;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.bottom-background-div {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 0 !important;
}

.pricing-header {
    color: white;
    margin: 10px;
}

.pricing-text {
    color: white;
    margin: 10px 0px 10px 0px;
    width: 80%;
}

.symbol-circle {
    display: flex;
    background-color: #121027;
    border: 2px white solid;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    justify-content: center;
    margin-bottom: -30px;
}

.symbol {
    margin: auto;
    color: white;
}

.bullet-point {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
}

.point-text {
    margin: 0;
    line-height: 20px;
}

.bullet-divider {
    background-color: #121027;
}
