@import './Colors.scss';

.home-container-row {
    padding: 10px;
    display: flex !important;
    margin: 10px 10px 0px 10px !important;
}
.first-contact>.container>.row>.col-md-auto>img {
    margin-top: -110px;
}

.home-container{
    background-color: $completeit-background;
}

@media (min-width: 992px) {
    .home-container-row {
        text-align: left !important;
    }

    .onlyMobile{
        display: none;
    }
}

@media (max-width: 992px) {
    .home-container-row {
        text-align: center;
        padding: 0px;
        margin: 10px 0px 0px 0px !important;
    }

    .contact-row{
        padding: 10px !important;
        
    }

    .col-icon {
        // display: none;
        justify-content: center;
    }

    .first-contact>.container>.row>.col-md-auto {
        text-align: center;
        img {
            margin-top: -10vh;
            width: 30vw;
        }
    }
    .icon-container{
        margin-bottom: 10px !important;
    }
    .onlyWeb{
        display: none !important;
    }

    .col{
        padding: 0px !important;
    }
}



.col-icon {
    vertical-align: middle; 
    display: flex;
}

.icon-container{
    color: white;
    height: fit-content;
    width: fit-content;
    padding: 20px;
    border-radius: 35%;
    background-image: $gradiant-default;

}

.first-contact {
    background-image: $gradiant-default;
    color: white;
    margin-top: 110px;
    padding-bottom: 10px;
}

.about{
    text-align: center;
}

